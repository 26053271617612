<template>
  <div class="from-panel" ref="panel">
    <div class="from-title">
<!--      <el-button icon="el-icon-back" type="info" size="mini" circle plain style="margin-right: 15px" @click="$router.push('/')"></el-button>-->
      <span>{{ $t('common.title') }}</span>
      <div>
        <el-button type="primary" icon="el-icon-plus" size="mini" @click="newProcess('0')">
          {{ $t('formPanel.createProcess') }}
        </el-button>
        <el-button icon="el-icon-plus" @click="addGroup" size="mini">{{ $t('formPanel.createGroup') }}</el-button>
      </div>
    </div>
<!--    group="group" handle=".el-icon-rank" filter=".undrag"
               @start="groupsSort = false"@end="groupSort"
               :options="{animation: 300 ,sort:true, scroll: true, chosenClass:'choose'}"-->
    <div v-if="groups.length>0" :list="groups"
    >
      <div :class="{'form-group':true, 'undrag': (group.id === 0 || group.id === undefined)}"
           v-for="(group, index) in groups" :key="index"
      >
        <div class="form-group-title">
          <span>{{ group.name }}</span>
          <span>({{ group.items.length }})</span>
<!--          <i class="el-icon-rank" title=""></i>-->
          <div v-if="!(group.id === 0 || group.id === undefined)">
            <el-dropdown>
              <el-button type="text" icon="el-icon-setting">{{ $t('formPanel.edit') }}</el-button>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item icon="el-icon-edit-outline" @click.native="editGroup(group)">
                  {{ $t('formPanel.updateGroup') }}
                </el-dropdown-item>
                <el-dropdown-item icon="el-icon-delete" @click.native="delGroup(group)">{{
                    $t('formPanel.deleteGroup')
                  }}
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
        </div>
        <draggable style="width: 100%; min-height:25px" :list="group.items" group="from" @end="groupSort"
                   v-show="!groupsSort" filter=".undrag"
                   :options="{animation: 300, delay: 200, chosenClass:'choose', scroll: true, sort:true}"
        >
          <div :class="{'form-group-item':true, 'undrag': false}" v-for="(item, index) in group.items"
               :key="index"
          >
            <div v-if="item.templateName.length>10">
              <el-tooltip placement="top">
                <div slot="content">{{ item.templateName }}</div>
                <div>
                  <i :class="item.logo.icon" :style="'background: '+item.logo.background"></i>
                  <span>{{ item.templateName.substring(0, 9) + '...' }}</span>
                  <br>
                </div>
              </el-tooltip>
            </div>
            <div v-if="item.templateName.length <= 10">
              <i :class="item.logo.icon" :style="'background: '+item.logo.background"></i>
              <span>{{ item.templateName }}</span>
              <br>
            </div>
            <el-tooltip placement="top">
              <div slot="content">{{ item.remark }}</div>
              <div
                  style="overflow: hidden;  text-overflow: ellipsis;display: -webkit-box;-webkit-line-clamp:1;-webkit-box-orient: vertical;text-align: justify;"
              >{{ item.remark }}
              </div>
            </el-tooltip>
            <div>
              <span :style="{'color':item.isStop ?'#f56c6c':''}">
                {{ $t(item.isStop ? 'formPanel.button.stop' : 'formPanel.button.use') }}</span>
            </div>
            <div>
              <el-button type="text" icon="el-icon-edit-outline" size="mini" @click="editFrom(item, group)">
                {{ $t('formPanel.button.edit') }}
              </el-button>
              <el-button v-if="item.groupId!==-1" type="text"
                         :icon="item.isStop ? 'el-icon-circle-check':'el-icon-circle-close'" size="mini"
                         @click="stopFrom(item)"
              >
                {{ $t(item.isStop ? 'formPanel.button.use' : 'formPanel.button.stop') }}
              </el-button>
              <el-button type="text" icon="el-icon-delete" size="mini" @click="deleteForm(item)">
                {{ $t('formPanel.button.delete') }}
              </el-button>
              <el-popover v-if="item.groupId!==-1" placement="left" trigger="click" width="400" style="margin-left: 10px"
                          @show="moveSelect === null"
              >
                <el-radio-group v-model="moveSelect" size="mini">
                  <el-radio :label="g.id" border v-for="g in groups" :key="g.id" v-show="g.id > 1"
                            :disabled="g.id === group.id" style="margin: 10px;"
                  >{{ g.name }}
                  </el-radio>
                </el-radio-group>
                <div style="text-align: right; margin: 0">
                  <el-button type="primary" size="mini" @click="moveFrom(item)">提交</el-button>
                </div>
                <el-button slot="reference" type="text" icon="el-icon-s-promotion" size="mini"
                > {{ $t('formPanel.button.move') }}
                </el-button>
              </el-popover>
            </div>
          </div>
        </draggable>
        <div style="text-align: center" v-if="group.items === undefined || group.items.length === 0">
          <el-button style="padding-top: 0" type="text" icon="el-icon-plus" @click="newProcess(group.id)">
            {{ $t('formPanel.createTemplate') }}
          </el-button>
        </div>
      </div>
    </draggable>
	<!--
    <el-empty v-else description="暂无流程审批"></el-empty>
	-->
    </div>
    <el-empty v-else :description="$t('formPanel.emptyTips')"></el-empty>
  </div>
</template>

<script>
import draggable from "vuedraggable";
import {
  getFormGroups, addGroup, updateGroup,
  groupItemsSort, deleteGroup, modifyTemplateStatus
} from '@/api/group'
import {
  removeTemplate, moveForm
} from '@/api/process'


export default {
  name: "FormsPanel",
  components: {draggable},
  data() {
    return {
      moveSelect: '',
      visible: false,
      groupsSort: false,
      groups: []
    }
  },
  mounted() {
    this.getGroups()
  },
  methods: {
    exit() {
      const title = this.$t('common.popTips')
      const message = this.$t('formPanel.exitPopMsg')
      this.$confirm(message, title, {
        confirmButtonText: this.$t('common.exit'),
        cancelButtonText: this.$t('common.cancel'),
        type: 'warning'
      }).then(() => {
        sessionStorage.removeItem("token")
        this.$router.push('/')
      })
    },
    getGroups() {
      getFormGroups().then(rsp => {
        this.groups = rsp.data
      })
    },
    newProcess(groupId) {
      // this.$store.commit("setTemplate", this.getTemplateData());
      this.$store.commit("SET_IS_EDIT", false);
      localStorage.setItem('edit_groupId', groupId)
      this.$router.push('/admin/design')
    },
    groupSort() {
      this.groupsSort = false
      groupItemsSort(this.groups).then(resp => {
        this.getGroups()
      })
    },
    addGroup() {
      const tips = this.$t('common.popTips')
      const message = this.$t('formPanel.popGroupMsg')
      this.$prompt(message, tips, {
        confirmButtonText: this.$t('common.yes'),
        cancelButtonText: this.$t('common.cancel'),
        inputPattern: /^[\u4E00-\u9FA5A-Za-z0-9\\-]{1,30}$/,
        inputErrorMessage: this.$t('formPanel.errorMsg'),
        inputPlaceholder: this.$t('formPanel.popGroupMsg'),
      }).then(({value}) => {
        addGroup({name: value}).then(resp => {
          this.$message.success(this.$t("tips.addSuccess"))
          this.getGroups()
        })
      })
    },
    editGroup(group) {
      const tips = this.$t('common.popTips')
      const message = this.$t('formPanel.popGroupMsg')
      this.$prompt(message, tips, {
        confirmButtonText: this.$t('common.yes'),
        cancelButtonText: this.$t('common.cancel'),
        inputPattern: /^[\u4E00-\u9FA5A-Za-z0-9\\-]{1,30}$/,
        inputErrorMessage: this.$t('formPanel.errorMsg'),
        inputPlaceholder: this.$t('formPanel.popGroupMsg'),
        inputValue: group.name
      }).then(({value}) => {
        updateGroup({id: group.id, name: value}).then(resp => {
          this.$message.success(this.$t("tips.editSuccess"))
          this.getGroups()
        })
      })
    },
    delGroup(group) {
      const tips = this.$t('common.popTips')
      const messages = this.$t('formPanel.popDeleteGroupMsg', {name: group.name})
      this.$confirm(messages, tips, {
        confirmButtonText: this.$t('common.yes'),
        cancelButtonText: this.$t('common.cancel'),
        type: 'warning'
      }).then(() => {
        deleteGroup(group.id).then(resp => {
          this.$message.success(this.$t("tips.deleteSuccess"))
          this.getGroups()
        })
      })
    },
    editFrom(item) {
      this.$router.push("/admin/design?code=" + item.templateId);
    },
    stopFrom(item) {
      const tips = this.$t('common.popTips')
      const edit = this.$t(item.isStop ? 'formPanel.button.stop' : 'formPanel.button.use')
      const name = '<span style="font-weight: bold">【' + item.templateName + '】</span>';
      this.$confirm(this.$t('formPanel.popStopMsg', {edit: edit, name: name}), tips, {
        confirmButtonText: this.$t('common.yes'),
        cancelButtonText: this.$t('common.cancel'),
        type: 'warning',
        dangerouslyUseHTMLString: true
      }).then(() => {
        let type = item.isStop ? '1' : '0'
        modifyTemplateStatus(type, item.templateId).then(resp => {
          this.$message.success(this.$t("tips.editSuccess"))
          this.getGroups()
        })
      })
    },
    deleteForm(item) {
      const tips = this.$t('common.popTips')
      const name = '<span style="font-weight: bold">【' + item.templateName + '】</span>'
      const messages = this.$t('formPanel.popDeleteMsg', {name: name})
      this.$confirm(messages, tips, {
        confirmButtonText: this.$t('common.yes'),
        cancelButtonText: this.$t('common.cancel'),
        type: 'warning',
        dangerouslyUseHTMLString: true
      }).then(() => {
        removeTemplate(item.templateId).then(resp => {
          this.$message.success(this.$t("tips.deleteSuccess"))
          this.getGroups()
        })
      })
    },
    moveFrom(item) {
      if (this.moveSelect === null || this.moveSelect === '') {
        this.$message.error(this.$t("formPanel.selectGroup"))
        return
      }
      moveForm(item.templateId, this.moveSelect).then(resp => {
        this.$message.success(this.$t("tips.moveSuccess"))
        this.getGroups()
        this.moveSelect = null
      })
    },
    getTemplateData(data) {
      return data
    }
  }
}
</script>

<style lang="less" scoped>
body {
  background: #ffffff !important;
}

.undrag {
  background: #ebecee !important;
}

.from-panel {
  padding: 50px 100px;
  min-width: 500px;
  background: #ffffff;

  /deep/ .from-title {
    div {
      float: right;

      .el-button {
        border-radius: 15px;
      }
    }
  }
}

.choose {
  background: #e9ebee;
}

.form-group {
  margin: 20px 0;
  padding: 5px 0px;
  border-radius: 10px;
  border: 1px solid #d3d3d3;
  box-shadow: 1px 1px 10px 0 #d2d2d2;

  &:hover {
    box-shadow: 1px 1px 12px 0 #b3b3b3;
  }

  .form-group-title {
    padding: 5px 20px;
    height: 40px;
    line-height: 40px;
    border-bottom: 1px solid #d3d3d3;

    .el-icon-rank {
      display: none;
      cursor: move;
    }

    &:hover {
      .el-icon-rank {
        display: inline-block;
      }
    }

    div {
      display: inline-block;
      float: right;
    }

    span:first-child {
      margin-right: 5px;
      font-size: 15px;
      font-weight: bold;
    }

    span:nth-child(2) {
      color: #656565;
      font-size: small;
      margin-right: 10px;
    }

    /deep/ .el-button {
      color: #404040;
      margin-left: 20px;

      &:hover {
        color: #2b2b2b;
      }
    }
  }

  .form-group-item:nth-child(1) {
    border-top: none !important;
  }

  .form-group-item {
    color: #3e3e3e;
    font-size: small;
    padding: 10px 0;
    margin: 0 20px;
    height: 40px;
    position: relative;
    line-height: 40px;
    border-top: 1px solid #d3d3d3;

    div {
      display: inline-block;
    }

    i {
      border-radius: 10px;
      padding: 7px;
      font-size: 20px;
      color: #ffffff;
      margin-right: 10px;
    }

    div:nth-child(1) {
      float: left;
    }

    div:nth-child(2) {
      position: absolute;
      color: #7a7a7a;
      font-size: 12px;
      left: 200px;
      max-width: 300px;
      overflow: hidden;
    }

    div:nth-child(3) {
      position: absolute;
      right: 30%;
    }

    div:nth-child(4) {
      float: right;
    }
  }
}

@media screen and (max-width: 1000px) {
  .desp {
    display: none !important;
  }
}

@media screen and (max-width: 800px) {
  .from-panel {
    padding: 50px 10px;
  }
}
</style>
